
import { Component, Prop, Vue } from "vue-property-decorator";
import { bus } from "@/main";

@Component
export default class DossierTaskSettings extends Vue {
    @Prop({ required: true }) activityId!: number;

    show: boolean = false;

    bus = bus;

    get clickOutsideConfig() {
        return {
            handler: this.closeUserSelect,
            isActive: this.show,
            capture: true,
        };
    }
    
    closeUserSelect() {
        this.show = false;
    }
}
